import Main from "./main";




function App() {
  return (
    <div className="App">
      <Main/>
    </div>
  );
}

export default App;
