import React, { useState, useRef } from "react";
import { read, utils } from 'xlsx';
import { SyncOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';

const ExcellImport = (props) => {

    const refs = useRef();
    const [datas, setDatas] = useState([]);
    const [header, setHeader] = useState([]);
    const [labels, setLabels] = useState("");
    const [loading, setLoading] = useState(false);

    const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            setLoading(true);
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { defval: "", raw: false, dateNF: "dd/mm/yyyy" });


                    let out = [];



                    rows.map((item, index) => {
                        if (index === 0) {
                            setHeader(Object.keys(item))
                        }


                        out.push(Object.keys(item).map(function (k) { return item[k] }))

                    })
                    setLoading(false);
                    setDatas(out);

                }
            }
            reader.readAsArrayBuffer(file);

        }


    }

    const resetValue = () => {
        setHeader([]);
        setDatas([]);
        setLabels("");
        refs.current.value = "";
    }




    return (
        <>
            <div className="row mb-5 mt-1">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" ref={refs} name="file" className="form-control" id="inputGroupFile" required onChange={handleImport}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 text-end">
                            <input type="text" className="form-control" onChange={e => setLabels(e.target.value)} id="exampleFormControlInput1" placeholder="Activity Label" />
                        </div>
                        <div className="col-md-2 text-end">
                            <div className="d-grid gap-2">
                                <button type="button" className='btn btn-primary' onClick={() => props.importDb(datas, header, labels)}>Import Data</button>
                            </div>
                        </div>
                        <div className="col-md-1 text-end">
                            <div className="d-grid gap-2">
                                <button type="button" className='btn btn-danger' onClick={() => resetValue()}><SyncOutlined /></button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            {loading ? <Skeleton /> :
                <div className="row">
                    <div className="col-sm-12 ">
                        <h3>
                            <>
                                {
                                    datas.length
                                        ? "Total rows: " + datas.length : "No data"
                                }

                            </>
                        </h3>
                    </div>

                    <div className="col-sm-12 ">
                        <table className="table">
                            {header.length > 0 &&
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        {
                                            header.length
                                                ?
                                                header.map((d, index) => (

                                                    <th key={index} scope="col">{d}</th>
                                                ))
                                                :
                                                <th scope="col">Id</th>
                                        }
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {
                                    datas.length
                                        ?
                                        datas.slice(0, 20).map((d, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>

                                                {d.map(element => (
                                                    <td>{element}</td>
                                                ))}

                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td colSpan="5" className="text-center">No data Found.</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>

    );
};

export default ExcellImport;