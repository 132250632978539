import React, { Component } from 'react'
import axios from 'axios';
import './App.css';
import { Card, Drawer, notification, Spin, Alert } from 'antd';
import ExcellImport from './ExcellImport';
import "bootstrap/scss/bootstrap.scss";
import moment from 'moment';

export default class main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            qr: "",
            open: false,
            label: "",
            loadingImport: false,
            labelsData: [],
            selectedActivity: "0",
            queryBlock: "1",
            dbBlock: "0",
            qrResultBlock: [],
            colData: [],
            dbResult: [],
            loadingDb: false,
            history: [],
        }
    }

    onChange = (e) => {

        const ss = e.target.value;


        let qrBlock = ss.split(";");

        this.setState({
            qr: e.target.value,
            qrResultBlock: qrBlock
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            labelId: this.state.selectedActivity,
            dbBlock: this.state.dbBlock,
            qr: this.state.qrResultBlock[parseInt(this.state.queryBlock) - 1]

        }

        if (typeof data.qr === "undefined") {
            notification.error({
                message: 'Please check QR code',
                description:
                    'Please check QR code',
            })

        } else {

            this.setState({

                loadingDb: true,
            });

            axios.post(process.env.REACT_APP_ADDRESS + 'ImportDb/getQr', data)
                .then(response => {

                    this.setState({
                        dbResult: response.data.data,
                        history: response.data.history,
                        loadingDb: false,
                        qr: "",
                        qrResultBlock: []
                    });

                    if (response.data.data.length < 1) {
                        notification.error({
                            message: 'No results found. ',
                            description:
                                "'" + this.state.qrResultBlock[parseInt(this.state.queryBlock) - 1] + "' query not found !!!"
                        });
                    }

                })
                .catch(error => {


                });
        }

    }

    onClose = () => {
        this.setState({
            open: false
        })
    }

    open = () => {
        this.setState({
            open: true
        })

    }




    importDb = (datas, header, label) => {

        if (label.length < 3) {
            notification.error({
                message: 'Please add activity label',
                description:
                    'must be set label',
            })

        } else if (datas.length < 1 || header.length < 1) {

            notification.error({
                message: 'Please Check Excel file',
                description:
                    'Please check excel file',
            })


        } else {
            const data = {
                header: header,
                data: datas,
                label: label
            }

            this.setState({
                open: false,
                loadingImport: true,
            })

            axios.post(process.env.REACT_APP_ADDRESS + 'ImportDb/addData', data)
                .then(response => {

                    this.setState({
                        loadingImport: false,
                    });

                    if (response.data.return) {
                        notification.success({
                            message: 'Excel file imported.',
                            description:
                                'Imported successfully',
                        })

                    } else {
                        notification.error({
                            message: 'Something went wrong. ',
                            description:
                                'Please Check Excel file',
                        })
                    }

                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loadingImport: false,
                    })

                });
        }

    }

    componentDidMount() {
        this.getLabel();
    }

    getLabel = () => {
        this.setState({
            loadingImport: true,
        })

        axios.post(process.env.REACT_APP_ADDRESS + 'ImportDb/getLabel')
            .then(response => {

                this.setState({
                    labelsData: response.data.data,
                    loadingImport: false,
                });
            })
            .catch(error => {


            });

    }

    getHeader = () => {

        this.setState({
            loadingImport: true,

        });

        const data = {
            id: this.state.selectedActivity
        }

        axios.post(process.env.REACT_APP_ADDRESS + 'ImportDb/getHeaderData', data)
            .then(response => {

                this.setState({
                    colData: response.data.data,
                    loadingImport: false,
                });
            })
            .catch(error => {


            });

    }

    setActivity = (e) => {
        this.setState({
            selectedActivity: e.target.value
        }, () => {
            this.getHeader();
        })
    }

    setQueryBlock = (e) => {
        this.setState({
            queryBlock: e.target.value
        })
    }

    setDbBlock = (e) => {
        this.setState({
            dbBlock: e.target.value
        })
    }



    render() {


        return (
            <>

                {this.state.loadingImport &&
                    <div className='load'>
                        <Spin tip="Loading" size="large">
                            <div className="content" />
                        </Spin>
                    </div>
                }
                <form onSubmit={this.handleSubmit}>
                    <div className="site-card-border-less-wrapper gray">
                        <Card title={
                            <div className='row'>
                                <div className='col-md-6'>Ticket System</div>
                                <div className='col-md-6 text-end'>
                                    <button type="button" className='btn btn-primary' onClick={this.open.bind(this)}>Set excel</button>
                                </div>
                            </div>
                        } bordered={false} style={{ width: "100%" }}>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className="form-floating">
                                        <select className="form-select" value={this.state.selectedActivity} id="lll" onChange={this.setActivity.bind(this)} >
                                            <option value="0">Select Activity</option>
                                            {
                                                this.state.labelsData.map((item, i) => (
                                                    <option key={i} value={item.id}>{item.label} / {item.created_at}</option>
                                                ))
                                            }



                                        </select>
                                        <label htmlFor="lll">Activity</label>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-floating">
                                        <input type="text" name="qr" onChange={this.onChange.bind(this)} value={this.state.qr} className="form-control" id="qr" placeholder="qr" disabled={this.state.selectedActivity === '0' ? true : false} />
                                        <label htmlFor="qr">Qr Code</label>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className="form-floating">
                                        <select className="form-select" value={this.state.queryBlock} id="lll2" onChange={this.setQueryBlock.bind(this)} disabled={this.state.selectedActivity === '0' ? true : false}>
                                            <option value="1">Block 1</option>
                                            <option value="2">Block 2</option>
                                            <option value="3">Block 3</option>
                                            <option value="4">Block 4</option>
                                            <option value="5">Block 5</option>
                                            <option value="6">Block 6</option>
                                            <option value="7">Block 7</option>
                                            <option value="8">Block 8</option>
                                            <option value="9">Block 9</option>
                                            <option value="10">Block 10</option>
                                            <option value="11">Block 11</option>
                                            <option value="12">Block 12</option>
                                            <option value="13">Block 13</option>
                                            <option value="14">Block 14</option>
                                            <option value="15">Block 15</option>
                                        </select>
                                        <label htmlFor="lll2">QR Block</label>
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className="form-floating">
                                        <select className="form-select" value={this.state.dbBlock} id="lll3" onChange={this.setDbBlock.bind(this)} disabled={this.state.selectedActivity === '0' ? true : false} >
                                            <option value="0">Select Db Block</option>
                                            {this.state.colData.filter(it => it !== null).map((item, index) => (
                                                <option value={index + 1}>{item}</option>
                                            ))}

                                        </select>
                                        <label htmlFor="lll3">Db Block (Excel Col number)</label>
                                    </div>
                                </div>
                                <div className='col-md-1'>
                                    <button type="submit" style={{ height: '57px', width: "100%" }} className='btn btn-warning btn-lg' disabled={(this.state.selectedActivity === '0' || this.state.dbBlock === '0') ? true : false} >Search</button>
                                </div>
                            </div>
                        </Card>

                        <div className='row mt-5'>
                            <div className='col-md-6'>
                                <Card title={"QR result"} bordered={false} style={{ width: "100%" }}>

                                    {this.state.qrResultBlock.length > 0 &&
                                        <ul>

                                            {this.state.qrResultBlock.map((item, i) => (
                                                <li>
                                                    <b>[{i + 1}]</b> {item}

                                                </li>
                                            ))}

                                        </ul>
                                    }
                                </Card>
                            </div>

                            <div className='col-md-6'>
                                <div className='row'>

                                    <Card title={"Status result"} bordered={false} style={{ width: "100%" }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                {this.state.dbResult.length > 0 ?
                                                    <>

                                                        {this.state.dbResult[25] === "0" ?
                                                            <h2>New Guest</h2>
                                                            :
                                                            <h2 style={{ color: "red" }}>Duplicate : {this.state.history.length}</h2>
                                                        }
                                                    </>
                                                    :
                                                    "No data"
                                                }

                                            </div>
                                            <div className='col-md-6'>
                                                <ul className="list-group">
                                                    {
                                                        this.state.history.map((item, index) => (
                                                            <li className="list-group-item"><b>{moment(item.created_at).format("DD.MM.YYYY -  HH:mm:ss")}</b></li>
                                                        ))
                                                    }

                                                </ul>
                                            </div>
                                        </div>

                                    </Card>


                                </div>

                            </div>
                            <div className='col-md-12 mt-5'>
                                <Card title={"Databse result"} bordered={false} style={{ width: "100%" }}>
                                    {this.state.loadingDb ?
                                        <Spin tip="Loading" size="large" />
                                        :
                                        <>
                                            {
                                                this.state.dbResult.length ?


                                                    <div className='tableParent'>

                                                        <table className="table">
                                                            {this.state.dbResult.length > 0 &&
                                                                <>
                                                                    <thead>
                                                                        <tr>
                                                                            {this.state.colData.map((item, index) => (
                                                                                <th scope="col">{item}</th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            {this.state.dbResult.map((item, i) => (


                                                                                <th key={i} scope="row">{item}</th>


                                                                            ))}
                                                                        </tr>
                                                                    </tbody>
                                                                </>


                                                            }
                                                        </table>
                                                    </div>



                                                    : ""


                                            }
                                        </>
                                    }
                                </Card>
                            </div>
                        </div>
                    </div>
                </form>

                <Drawer
                    title="Import"
                    placement="right"
                    width={"95%"}
                    onClose={this.onClose.bind(this)}
                    open={this.state.open}>
                    <ExcellImport importDb={this.importDb} label={this.setLabel} />

                </Drawer>
            </>
        )
    }
}




